<template>
  <section class="invoice-preview-wrapper">

    <b-row
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          id="quotation"
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body  class="invoice-padding pb-2" id="email-template">
            <html lang="en" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">

            <head>
              <meta charset="utf-8">
              <meta name="x-apple-disable-message-reformatting">
              <meta http-equiv="x-ua-compatible" content="ie=edge">
              <meta name="viewport" content="width=device-width, initial-scale=1">
              <meta name="format-detection" content="telephone=no, date=no, address=no, email=no">
              <!--[if mso]>
              <xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch></o:OfficeDocumentSettings></xml>
              <style>
                td,th,div,p,a,h1,h2,h3,h4,h5,h6 {font-family: "Segoe UI", sans-serif; mso-line-height-rule: exactly;}
              </style>
            <![endif]-->
              <title>冰山數據</title>
              <link href="https://fonts.googleapis.com/css?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700" rel="stylesheet" media="screen">
            </head>

            <body style="margin: 0; padding: 0; width: 100%; word-break: break-word; -webkit-font-smoothing: antialiased; --bg-opacity: 1; background-color: #eceff1; background-color: rgba(236, 239, 241, var(--bg-opacity));">
                <table style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif; width: 100%;" width="100%" cellpadding="0" cellspacing="0" role="presentation">
                  <tr>
                    <td align="center" style="--bg-opacity: 1; background-color: #1c1c1c !important; font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;" bgcolor="rgba(236, 239, 241, var(--bg-opacity))">
                      <table class="sm-w-full" style="font-family: 'Montserrat',Arial,sans-serif; width: 600px;" width="600" cellpadding="0" cellspacing="0" role="presentation">
                        <tr>
                          <td class="sm-py-32 sm-px-24" style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif; padding: 48px; text-align: center;" align="center">
                            <a href="https://mark.hk">
                              <img src="https://markhk.cn/wp-content/uploads/2022/04/logo.png" width="155" alt="Iceberg Data" style="border: 0; max-width: 100%; line-height: 100%; vertical-align: middle;">
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td align="center" class="sm-px-24" style="font-family: 'Montserrat',Arial,sans-serif;">
                            <table style="font-family: 'Montserrat',Arial,sans-serif; width: 100%;" width="100%" cellpadding="0" cellspacing="0" role="presentation">
                              <tr>
                                <td class="sm-px-24" style="--bg-opacity: 1; background-color: #bfbfbf !important; border-radius: 4px; font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif; font-size: 14px; line-height: 24px; padding: 48px; text-align: left; --text-opacity: 1; color: #626262; color: rgba(98, 98, 98, var(--text-opacity));" bgcolor="rgba(255, 255, 255, var(--bg-opacity))" align="left">
                                  <p style="font-weight: 600; font-size: 18px; margin-bottom: 0;">Hey</p>
                                  <p style="font-weight: 700; font-size: 20px; margin-top: 0; --text-opacity: 1; color: #702da6 !important;">尊敬的合作伙伴!</p>
                                  <p class="sm-leading-32" style="font-weight: 600; font-size: 20px; margin: 0 0 24px; --text-opacity: 1; color: #263238; color: rgba(38, 50, 56, var(--text-opacity));">
                                    您的项目已经顺利结项了！
                                  </p>
                                  <p style="font-weight: 500; font-size: 16px; margin-bottom: 5px;">您的项目信息如下：</p>
                                  
                                  <p>合作方信息</p>
                                  <ul>
                                    <li>甲方：{{ statementData.statement_mail_contents.company_name }}</li>
                                    <li>乙方：玉案（北京）软件有限公司</li>
                                  </ul>

                                  <p>项目信息</p>
                                  <ul>
                                    <li>项目名称：{{ statementData.statement_mail_contents.project_name }}</li>
                                    <li>项目编号：{{ statementData.project_id}}</li>
                                    <li>任务书绑定的合同编号：{{ statementData.statement_mail_contents.contract_iceberg_id }}</li>
                                  </ul>


                                  <p>准确率标准</p>
                                  <table style="width:100%;border: 1px solid #dddddd; text-align:center !important">
                                    <tr>
                                      <th>元素</th>
                                      <th>准确率（%）</th>
                                      <th>备注</th>
                                    </tr>
                                    <tr v-for="(i,index) in statementData.statement_mail_contents.accuracy" :key="index">
                                      <td>{{ i["元素"] }}</td>
                                      <td>{{ i["准确率（%）"] }}</td>
                                      <td>{{ i["备注"] }}</td>
                                    </tr>
                                  </table>

                                  <p>结算统计表</p>
                                  <table style="width:100%;border: 1px solid #dddddd; text-align:center !important">
                                    <tr>
                                      <th>元素单位</th>
                                      <th>单价（元）</th>
                                      <th>备注</th>
                                      <th>数量</th>
                                      <th>小计（元）</th>
                                    </tr>
                                    <tr v-for="(i,index) in statementData.statement_mail_contents.price" :key="index">
                                      <td>{{ i.element }}</td>
                                      <td>{{ i.price }}</td>
                                      <td>{{ i.note }}</td>
                                      <td>{{ i.quantity }}</td>
                                      <td>{{ i.total }}</td>
                                    </tr>
                                  </table>
                                  <p style="text-align:right">合计：{{ statementData.summary }}</p>
                                  <p style="text-align:right">含税：1%</p>
                                  <p style="text-align:right">币种：￥人民币元</p>

                                  <hr>
                                  <p style="font-weight: 500; font-size: 16px; margin-bottom: 0;">我们还可以提供哪些服务？</p>
                                  <p style="font-size: 13px; margin-bottom: 0;">冰山數據是一家专业的AI数据公司，荣获国家高新技术企业认证、中关村高新技术企业认证、ISO9001认证、启迪之星壹计划投资企业、华为云和亚马逊AWS生态合作伙伴等诸多荣誉，并拥有数据安全专利一项和软件著作权10余项。</p>
                                  <p style="font-size: 13px; margin-bottom: 0;">我们为全球的AI和科技企业提供高质量，低成本的一站式AI数据服务，服务领域覆盖各行各业，通过我们的全球资源和自动化的标注工具帮助企业降本增效。</p>
                                  <ul style="margin-bottom: 24px;">
                                    <li>
                                      图像、视频采集和标注。
                                    </li>
                                    <li>
                                      全球语音采集和标注。
                                    </li>
                                    <li>
                                      点云数据标注。
                                    </li>
                                    <li>
                                      自然语言类文本标注。
                                    </li>
                                    <li>
                                      搜索引擎测评……
                                    </li>
                                  </ul>
                                  <p style="font-size: 13px; margin-bottom: 0;">如果您还有更多想要了解的内容，请访问我们的官网并订阅，我们会经常性发布AI和数据标注领域的最新见闻。</p>
                                  <table style="margin-top:10px;font-family: 'Montserrat',Arial,sans-serif;" cellpadding="0" cellspacing="0" role="presentation">
                                    <tr>
                                      <td style="mso-padding-alt: 16px 24px; --bg-opacity: 1; background-color: #464646 !important; border-radius: 4px; font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;" bgcolor="rgba(115, 103, 240, var(--bg-opacity))">
                                        <a href="https://mark.hk" style="display: block; font-weight: 600; font-size: 14px; line-height: 100%; padding: 16px 24px; --text-opacity: 1; color: #ffffff; color: rgba(255, 255, 255, var(--text-opacity)); text-decoration: none;">访问官网 &rarr;</a>
                                      </td>
                                    </tr>
                                  </table>
                                  <table style="font-family: 'Montserrat',Arial,sans-serif; width: 100%;" width="100%" cellpadding="0" cellspacing="0" role="presentation">
                                    <tr>
                                      <td style="font-family: 'Montserrat',Arial,sans-serif; padding-top: 32px; padding-bottom: 32px;">
                                        <div style="--bg-opacity: 1; background-color: #eceff1; background-color: rgba(236, 239, 241, var(--bg-opacity)); height: 1px; line-height: 1px;">&zwnj;</div>
                                      </td>
                                    </tr>
                                  </table>
                                  <p style="margin: 0 0 16px;">感谢您选择冰山數據 <br>冰山數據全体敬上！</p>
                                </td>
                              </tr>
                              <tr>
                                <td style="font-family: 'Montserrat',Arial,sans-serif; height: 20px;" height="20"></td>
                              </tr>
                              
                              <tr>
                                <td style="font-family: 'Montserrat',Arial,sans-serif; height: 16px;" height="16"></td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </div>
            </body>

            </html>
          </b-card-body>

        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
         
          <b-form-group
              label="接收人"
              label-for="recivers"
            >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              taggable
              push-tags
              placeholder=""
              label="recivers"
              v-model="receivers"
            />
          </b-form-group>

          <b-form-group
              label="抄送人"
              label-for="cc"
            >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              taggable
              push-tags
              placeholder=""
              label="cc"
              v-model="cc"
            />
          </b-form-group>

          <b-form-group
              label="项目规则附件"
              label-for="guidelineAttach"
            >
            <a
              class="text-primary"
              @click="loadFile(statementData.statement_id, statementData.statement_name)"
              :disabled="fileDownloading">
              <font-awesome-icon
                icon="fa-solid fa-paperclip"
              />
              {{ statementData.statement_name }}
            </a>
          </b-form-group>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="send"
            :disabled="!sendFinished"
          >
            发送
          </b-button>
        </b-card>
      </b-col>
    </b-row>


  </section>
</template>

<script>

import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle,BFormGroup,BFormInput,BFormTextarea
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import HtmlPreview from 'vue-html-viewer'
import vSelect from 'vue-select'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    Logo,
    flatPickr,
    vSelect,
    HtmlPreview
    },
    data(){
      return{
        fileDownloading:false,
        statementData:{
          "statement_id":null,
          "statement_mail_contents":{
            "company_name":""
          },
        },
        projectData:{},
        contactData:[],
        userData:[],
        sendFinished: true,
        cc:["bd@mark.hk","pm@mark.hk"],
        receivers:[],
      }
    },
  methods:{
    getStatement(){
      this.$axios.get("/collab/pm/get_statement/" + this.$route.params.statementId).then(resp =>{
        this.statementData = resp.data.data.statement
      })
    },
    getProject(){
      this.$axios.get("/collab/pm/get_project/" + this.$route.params.id).then(resp =>{
        this.projectData = resp.data.data.project
        var customers = []
        this.projectData.customer_bm.forEach(cbm =>{
          customers.push(cbm)
        })
        this.projectData.customer_pm.forEach(cpm =>{
          if(!customers.includes(cpm)){
            customers.push(cpm)
          }
        })
        var icebergs = []
        this.projectData.pm.forEach(pm =>{
          icebergs.push(pm)
        })
        this.projectData.bm.forEach(bm =>{
          if(!icebergs.includes(bm)){
            icebergs.push(bm)
          }
        })

        customers.forEach(contactId =>{
          this.getContact(contactId)
        })
        icebergs.forEach(userId =>{
          this.getUser(userId)
        })
      })
    },
    getContact(contactId){
      this.$axios.get("crm/contacts/system/get_contact/" + contactId).then(resp =>{
        this.contactData.push(resp.data.data.contact)
        this.receivers.push(resp.data.data.contact.email)
      })
    },
    getUser(userId){
      this.$axios.get("/manage/users/get_user/" + userId).then(resp =>{
        this.userData.push(resp.data.data.user)
        this.receivers.push(resp.data.data.user.user_email)
      })
    },
    checkValidEmail(email){
      var re = /\S+@\S+\.\S+/
      return re.test(email)
    },
    send(){
      var valid = true
      this.receivers.forEach(email =>{
        if(!this.checkValidEmail(email)){
          valid = false
          this.$toast.error("邮箱" + email + "的格式不正确")
        }
      })

      if(valid){
        var emailTemplate = document.getElementById("email-template").innerHTML
        var emailData = {
          "statementId": this.$route.params.statementId,
          "toList":this.receivers,
          "ccList":this.cc,
          "emailTemplate": emailTemplate
        }
        this.sendFinished = false,
        this.$axios.post("/collab/pm/send_statement_mail", emailData).then(resp =>{
          this.sendFinished = true
      })
      }
      
    },

    loadFile(statementId,fileName){
          this.fileDownloading = true
          this.$axios.get("/collab/pm/download_statement/" + statementId).then(res=>{
            this.fileDownloading = false

            if (res.data.status == "ok"){
              const url = this.$backend + '/download_by_token/' + res.data.data.token
              const link = document.createElement('a')
              link.setAttribute('href', url)
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              link.remove()
            }
          })
        },
  },
  created(){
    this.getStatement()
    this.getProject()
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>

<style lang="scss">
@media print {
  @page { margin: 0; size: landscape;}
  html{
    height:auto
  }
  // Global Styles
  body {
    background-color: transparent !important;
    height: auto;  
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
