import { render, staticRenderFns } from "./StatementMailView.vue?vue&type=template&id=7b660e66&scoped=true&"
import script from "./StatementMailView.vue?vue&type=script&lang=js&"
export * from "./StatementMailView.vue?vue&type=script&lang=js&"
import style0 from "./StatementMailView.vue?vue&type=style&index=0&id=7b660e66&prod&lang=scss&scoped=true&"
import style1 from "./StatementMailView.vue?vue&type=style&index=1&id=7b660e66&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b660e66",
  null
  
)

export default component.exports